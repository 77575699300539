import { useEffect, useState } from "react";

const LiveTimer = ({ initialTime }) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(interval);
          return 0;
        }

        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  return (
    <div className="py-[2px] px-1 rounded-[3px] bg-[#23252D]">
      <p className="text-[rgba(255,255,255,0.31)] text-[10px] font-normal tracking-[-0.35px]">
        {formatTime(time)}
      </p>
    </div>
  );
};

export default LiveTimer;
