import React from "react";
import { FavIcon, PinIcon, SupchatIcon, YoutubeIcon } from "./common/Icon";
import useDeviceType from "../hooks/useDeviceType";
import userAvatarImg from "../assets/img/yashikadp.png";

const Chatdatamodule = ({
  allData,
  pinData,
  setPinData,
  favData,
  setFavData,
}) => {
  const deviceType = useDeviceType();

  // To add chats into pindata
  const handleSelectClick = (item) => {
    const clickedItemName = item.name;
    if (!pinData.some((pinnedItem) => pinnedItem.name === clickedItemName)) {
      const newPinData = [item];
      setPinData(newPinData);
    }
  };

  // To show tag based on the data
  const handleTagIcon = (tag) => {
    if (tag === "SupChat") {
      return <SupchatIcon />;
    } else if (tag === "YouTube") {
      return <YoutubeIcon />;
    } else {
      return null;
    }
  };

  // To handle favourite item
  const handleFavClick = (item) => {
    if (!favData.some((favItem) => favItem.id === item.id)) {
      const updatedItem = {
        ...item,
        favtime: new Date(),
      };
      setFavData([updatedItem, ...favData]);
    } else {
      const updatedItem = favData.filter((favItem) => favItem.id != item.id);
      setFavData(updatedItem);
    }
  };

  // To find if the item is in fav or not
  const isItemInFav = (item) => {
    return !!favData.some((favItem) => favItem.id == item.id);
  };

  return (
    <>
      {allData.map((item, index) => (
        <div
          key={index}
          data-id={item.id}
          className={`chat-item ${
            deviceType == "mobile" ? "" : "sm:h-[89px]"
          } w-full group/main overflow-hidden rounded-lg flex items-start sm:items-center p-[12px_16px_16px_16px] gap-3 bg-[#1D1E25]`}
        >
          <div className="max-w-9 sm:max-w-12 max-h-9 sm:max-h-12">
            <img
              src={item.img}
              alt="img"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="flex flex-col gap-1 w-full">
            <div className="flex justify-between w-full items-center">
              <div className="flex gap-3 items-end">
                <h3 className="text-[#555978] text-[16px] font-semibold tracking-[-0.35px]">
                  {item.name}
                </h3>
                <h2
                  className={`text-base sm:text-xl font-bold tracking-[-0.35px] ${
                    item.seen ? "text-[#555978]" : "text-white"
                  }`}
                >
                  {item.price}
                </h2>
              </div>

              {/* Pin and fav for large screens */}
              <div
                className={`flex gap-4 max-sm:hidden items-center ${
                  deviceType == "mobile" && "!hidden"
                }`}
              >
                <button
                  className="flex group cursor-pointer duration-300 items-center gap-1 group-hover/main:opacity-100 group-hover/main:translate-x-0 translate-x-20 opacity-0
                  "
                  onClick={() => handleSelectClick(item)}
                >
                  <PinIcon />
                  <p className="text-[#EC6A5E] text-[12px] font-normal leading-[17px] group-hover:text-white duration-300">
                    Pin this comment
                  </p>
                </button>
                <button
                  onClick={() => handleFavClick(item)}
                  className="flex group cursor-pointer duration-300 items-center gap-1 group-hover/main:opacity-100 group-hover/main:translate-x-0 translate-x-20 opacity-0 delay-500"
                >
                  <FavIcon isFav={isItemInFav(item)} />
                  <p
                    className={`${
                      isItemInFav(item) ? "text-[#EC6A5E]" : "text-[#5A5E80]"
                    } duration-300 group-hover:text-white text-[12px] font-normal leading-[17px]`}
                  >
                    {isItemInFav(item) ? "Remove" : "Add to Favourites"}
                  </p>
                </button>
              </div>
            </div>
            <p>
              <span
                className={`bg-[linear-gradient(90deg,#2D2E3A_0%,#2D314E_59.9%,#2D2E3A_97.4%);] px-1 py-[2px] rounded-[3px] w-fit inline-flex flex-shrink-0  text-[#858A9C] text-[10px] font-normal tracking-[-0.35px] items-center mr-1 ${
                  item.tag === "" && "hidden"
                }`}
              >
                <span>{handleTagIcon(item.tag)}</span>
                {item.tag}
              </span>
              <span
                className={`text-xs font-normal align-super leading-[17px] ${
                  item.seen ? "text-[#555978]" : "text-white"
                }`}
              >
                {item.chatMessage}
              </span>
            </p>

            {/* Pin and fav for small screens */}
            <div
              className={`${
                deviceType == "mobile" ? "" : "sm:hidden"
              } flex max-[345px]:mt-2 gap-2 min-[344.98px]:gap-4 max-[375px]:flex-wrap items-center`}
            >
              <button
                onClick={() => handleSelectClick(item)}
                className={`flex group cursor-pointer duration-300 items-center gap-1 ${
                  deviceType !== "mobile" &&
                  "group-hover/main:opacity-100 group-hover/main:translate-x-0 translate-x-20 opacity-0"
                } `}
              >
                <PinIcon />
                <p className=" text-[#EC6A5E] text-[12px] font-normal leading-[17px] group-hover:text-white duration-300">
                  Pin this comment
                </p>
              </button>
              <button
                onClick={() => handleFavClick(item)}
                className={`flex group cursor-pointer duration-300 items-center gap-1 ${
                  deviceType !== "mobile" &&
                  "group-hover/main:opacity-100 group-hover/main:translate-x-0 translate-x-20 opacity-0 delay-500"
                }`}
              >
                <FavIcon isFav={isItemInFav(item)} />
                <p
                  className={`${
                    isItemInFav(item) ? "text-[#EC6A5E]" : "text-[#5A5E80]"
                  } duration-300 group-hover:text-white text-[12px] font-normal leading-[17px]`}
                >
                  {isItemInFav(item) ? "Remove" : "Add to Favourites"}
                </p>
              </button>
            </div>
          </div>
        </div>
      ))}
      {allData.length == 0 && (
        <p className="text-white line-clamp-1 text-[12px] font-normal  leading-[17px]">
          Nothing found!
        </p>
      )}
    </>
  );
};

export default Chatdatamodule;
