import Chatlayout from "./components/Chatlayout";

function App() {
  return (
    <main
      style={{}}
      className="w-full h-screen flex items-center justify-center 
     bg-[#1D1E25] font-plusJakartaSans"
    >
      <Chatlayout />
    </main>
  );
}

export default App;
