export const SettingIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_0_124)">
        <path
          d="M20.9999 12C20.9997 11.4483 20.9495 10.8977 20.8499 10.355L23.8929 8.6L20.8929 3.4L17.8489 5.159C17.0085 4.43993 16.0427 3.88194 14.9999 3.513V0H8.99993V3.513C7.95719 3.88194 6.99139 4.43993 6.15093 5.159L3.10693 3.4L0.106934 8.6L3.14993 10.355C2.95004 11.4426 2.95004 12.5574 3.14993 13.645L0.106934 15.4L3.10693 20.6L6.15093 18.842C6.99147 19.5607 7.95726 20.1184 8.99993 20.487V24H14.9999V20.487C16.0427 20.1181 17.0085 19.5601 17.8489 18.841L20.8929 20.6L23.8929 15.4L20.8499 13.645C20.9495 13.1023 20.9997 12.5517 20.9999 12ZM14.9999 12C14.9999 12.5933 14.824 13.1734 14.4943 13.6667C14.1647 14.1601 13.6962 14.5446 13.148 14.7716C12.5998 14.9987 11.9966 15.0581 11.4147 14.9424C10.8327 14.8266 10.2982 14.5409 9.87861 14.1213C9.45906 13.7018 9.17333 13.1672 9.05758 12.5853C8.94182 12.0033 9.00123 11.4001 9.2283 10.8519C9.45536 10.3038 9.83988 9.83524 10.3332 9.50559C10.8266 9.17595 11.4066 9 11.9999 9C12.7956 9 13.5586 9.31607 14.1213 9.87868C14.6839 10.4413 14.9999 11.2044 14.9999 12Z"
          fill="#52556C"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_124">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const SupchatIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.98163 17H7.73755L8.90422 12.3333H6.4933C6.2603 12.3332 6.03056 12.2786 5.82242 12.1739C5.61429 12.0692 5.43354 11.9172 5.29461 11.7302C5.15568 11.5431 5.06243 11.3262 5.02231 11.0967C4.98219 10.8671 4.99631 10.6314 5.06355 10.4083L7.30413 3H12.8265L11.0765 7.66667H13.4233C13.6903 7.66684 13.9523 7.73935 14.1814 7.8765C14.4104 8.01365 14.5981 8.21032 14.7243 8.44558C14.8506 8.68085 14.9107 8.94593 14.8984 9.21265C14.886 9.47937 14.8016 9.73775 14.6541 9.96033L9.98163 17Z"
        fill="#EC6A5E"
      />
    </svg>
  );
};
export const YoutubeIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-[#555978] group-hover:fill-[#FF2828]"
    >
      <path d="M16.7072 6.60848C16.5462 6.0024 16.0719 5.52465 15.4693 5.36248C14.3779 5.06848 10 5.06848 10 5.06848C10 5.06848 5.62208 5.06848 4.53008 5.36248C3.92808 5.52465 3.45383 6.00181 3.29283 6.60848C3 7.70748 3 9.99998 3 9.99998C3 9.99998 3 12.2925 3.29283 13.3915C3.45383 13.9976 3.92808 14.4753 4.53067 14.6375C5.62208 14.9315 10 14.9315 10 14.9315C10 14.9315 14.3779 14.9315 15.4699 14.6375C16.0719 14.4753 16.5462 13.9981 16.7078 13.3915C17 12.2925 17 9.99998 17 9.99998C17 9.99998 17 7.70748 16.7072 6.60848ZM8.5685 12.0819V7.91806L12.2272 9.99998L8.5685 12.0819Z" />
    </svg>
  );
};
export const Youtube2Icon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7072 6.60848C16.5462 6.0024 16.0719 5.52465 15.4693 5.36248C14.3779 5.06848 10 5.06848 10 5.06848C10 5.06848 5.62208 5.06848 4.53008 5.36248C3.92808 5.52465 3.45383 6.00181 3.29283 6.60848C3 7.70748 3 9.99998 3 9.99998C3 9.99998 3 12.2925 3.29283 13.3915C3.45383 13.9976 3.92808 14.4753 4.53067 14.6375C5.62208 14.9315 10 14.9315 10 14.9315C10 14.9315 14.3779 14.9315 15.4699 14.6375C16.0719 14.4753 16.5462 13.9981 16.7078 13.3915C17 12.2925 17 9.99998 17 9.99998C17 9.99998 17 7.70748 16.7072 6.60848ZM8.5685 12.0819V7.91806L12.2272 9.99998L8.5685 12.0819Z"
        fill="#FF2828"
      />
    </svg>
  );
};
export const FavIcon = ({ isFav }) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        isFav ? "fill-[#EC6A5E]" : "fill-[#5A5E80]"
      } group-hover:fill-white duration-300`}
    >
      <g clipPath="url(#clip0_0_626)">
        <path d="M7.29173 0.798706C6.82254 0.806004 6.36359 0.936978 5.96122 1.1784C5.55884 1.41983 5.2273 1.76315 5.00006 2.17371C4.77283 1.76315 4.44129 1.41983 4.03891 1.1784C3.63654 0.936978 3.17758 0.806004 2.7084 0.798706C1.96046 0.831202 1.25577 1.1584 0.748307 1.7088C0.240839 2.25921 -0.0281654 2.98809 6.40012e-05 3.73621C6.40012e-05 5.63079 1.99423 7.69996 3.66673 9.10287C4.04015 9.41668 4.51229 9.58872 5.00006 9.58872C5.48783 9.58872 5.95997 9.41668 6.3334 9.10287C8.0059 7.69996 10.0001 5.63079 10.0001 3.73621C10.0283 2.98809 9.75929 2.25921 9.25182 1.7088C8.74435 1.1584 8.03967 0.831202 7.29173 0.798706Z" />
      </g>
      <defs>
        <clipPath id="clip0_0_626">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const Fav2Icon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_0_665)">
        <path
          d="M7.29173 0.798706C6.82254 0.806004 6.36359 0.936978 5.96122 1.1784C5.55884 1.41983 5.2273 1.76315 5.00006 2.17371C4.77283 1.76315 4.44129 1.41983 4.03891 1.1784C3.63654 0.936978 3.17758 0.806004 2.7084 0.798706C1.96046 0.831202 1.25577 1.1584 0.748307 1.7088C0.240839 2.25921 -0.0281654 2.98809 6.40012e-05 3.73621C6.40012e-05 5.63079 1.99423 7.69996 3.66673 9.10287C4.04015 9.41668 4.51229 9.58872 5.00006 9.58872C5.48783 9.58872 5.95997 9.41668 6.3334 9.10287C8.0059 7.69996 10.0001 5.63079 10.0001 3.73621C10.0283 2.98809 9.75929 2.25921 9.25182 1.7088C8.74435 1.1584 8.03967 0.831202 7.29173 0.798706Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_665">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const PaidIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-[#5A5E80] group-hover:fill-white"
    >
      <g clipPath="url(#clip0_0_636)">
        <path d="M5 0C2.24292 0 0 2.24292 0 5C0.25125 11.6242 9.75 11.6225 10 5C10 2.24292 7.75708 0 5 0ZM5.41667 7.08333V7.5C5.41458 8.045 4.58542 8.04458 4.58333 7.5V7.08333H4.47167C4.02708 7.08333 3.61208 6.84417 3.38917 6.45875C3.27375 6.25917 3.34208 6.00458 3.54083 5.88958C3.74042 5.77333 3.99542 5.8425 4.11 6.04125C4.18458 6.17042 4.32292 6.25 4.47125 6.25H5.41625C5.83167 6.27708 6.0175 5.60417 5.56458 5.51667L4.2975 5.30542C2.83833 5.035 3.1525 2.90833 4.58292 2.91667V2.5C4.58542 1.955 5.41417 1.95542 5.41625 2.5V2.91667H5.52792C5.9725 2.91667 6.3875 3.15625 6.61042 3.54167C6.72583 3.74083 6.6575 3.99542 6.45875 4.11083C6.25875 4.22625 6.00417 4.15792 5.88958 3.95875C5.815 3.83 5.67667 3.75042 5.52833 3.75042H4.58333C4.16792 3.72333 3.9825 4.39625 4.435 4.48375L5.70208 4.695C7.16125 4.96542 6.84708 7.09167 5.41667 7.08333Z" />
      </g>
      <defs>
        <clipPath id="clip0_0_636">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const Paid2Icon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_0_675)">
        <path
          d="M5 0C2.24292 0 0 2.24292 0 5C0.25125 11.6242 9.75 11.6225 10 5C10 2.24292 7.75708 0 5 0ZM5.41667 7.08333V7.5C5.41458 8.045 4.58542 8.04458 4.58333 7.5V7.08333H4.47167C4.02708 7.08333 3.61208 6.84417 3.38917 6.45875C3.27375 6.25917 3.34208 6.00458 3.54083 5.88958C3.74042 5.77333 3.99542 5.8425 4.11 6.04125C4.18458 6.17042 4.32292 6.25 4.47125 6.25H5.41625C5.83167 6.27708 6.0175 5.60417 5.56458 5.51667L4.2975 5.30542C2.83833 5.035 3.1525 2.90833 4.58292 2.91667V2.5C4.58542 1.955 5.41417 1.95542 5.41625 2.5V2.91667H5.52792C5.9725 2.91667 6.3875 3.15625 6.61042 3.54167C6.72583 3.74083 6.6575 3.99542 6.45875 4.11083C6.25875 4.22625 6.00417 4.15792 5.88958 3.95875C5.815 3.83 5.67667 3.75042 5.52833 3.75042H4.58333C4.16792 3.72333 3.9825 4.39625 4.435 4.48375L5.70208 4.695C7.16125 4.96542 6.84708 7.09167 5.41667 7.08333Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_675">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const SearchIcon = () => {
  return (
    <svg
      className="w-4 sm:w-[21px] h-4 sm:h-[21px]"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_0_27)">
        <path
          d="M20.7438 19.5064L15.5209 14.2835C16.9442 12.5428 17.644 10.3216 17.4755 8.07936C17.3071 5.83714 16.2832 3.74546 14.6158 2.23695C12.9484 0.728439 10.7649 -0.081477 8.51711 -0.0252741C6.26928 0.0309287 4.12904 0.94895 2.53909 2.5389C0.949133 4.12886 0.0311118 6.2691 -0.025091 8.51693C-0.0812938 10.7648 0.728622 12.9482 2.23713 14.6156C3.74564 16.2831 5.83733 17.3069 8.07954 17.4754C10.3218 17.6438 12.5429 16.944 14.2837 15.5207L19.5065 20.7436C19.6716 20.903 19.8926 20.9912 20.122 20.9892C20.3514 20.9872 20.5709 20.8952 20.7331 20.733C20.8954 20.5707 20.9874 20.3513 20.9894 20.1218C20.9914 19.8924 20.9032 19.6714 20.7438 19.5064ZM8.75018 15.75C7.36571 15.75 6.01233 15.3394 4.86118 14.5703C3.71004 13.8011 2.81283 12.7079 2.28302 11.4288C1.7532 10.1497 1.61458 8.74223 1.88468 7.38436C2.15477 6.02649 2.82146 4.77921 3.80043 3.80024C4.7794 2.82128 6.02668 2.15459 7.38454 1.8845C8.74241 1.6144 10.1499 1.75302 11.429 2.28284C12.708 2.81265 13.8013 3.70986 14.5705 4.861C15.3396 6.01214 15.7502 7.36552 15.7502 8.74999C15.7481 10.6059 15.0099 12.3851 13.6976 13.6974C12.3853 15.0097 10.6061 15.7479 8.75018 15.75Z"
          fill="#555978"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_27">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const PinIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_0_210)">
        <path
          className="group-hover:fill-white duration-300"
          d="M3.13957 7.45833L0.711237 9.87792L0.12207 9.28875L2.5504 6.86833L3.13957 7.45833ZM5.76457 8.61042C6.14634 8.22707 6.42171 7.75094 6.56359 7.22886C6.70547 6.70678 6.70897 6.15676 6.57374 5.63292L6.44374 5.10125L7.95207 3.58458L8.18957 3.8225C8.36495 4.00381 8.59854 4.11762 8.84941 4.14398C9.10028 4.17034 9.35242 4.10757 9.56165 3.96667C9.68506 3.87885 9.78782 3.76519 9.8628 3.63359C9.93777 3.50199 9.98315 3.35563 9.99576 3.2047C10.0084 3.05377 9.98792 2.90191 9.93583 2.75969C9.88374 2.61747 9.80127 2.48833 9.69415 2.38125L7.64332 0.328333C7.46781 0.14724 7.23425 0.0335846 6.98344 0.00723227C6.73264 -0.0191201 6.48055 0.0435066 6.27124 0.184166C6.14774 0.271941 6.04487 0.385601 5.96982 0.517219C5.89476 0.648837 5.84932 0.795244 5.83666 0.94623C5.82401 1.09721 5.84444 1.24914 5.89654 1.39142C5.94864 1.53369 6.03115 1.66289 6.13832 1.77L6.4079 2.03917L4.89915 3.55583L4.37082 3.42625C3.84652 3.29039 3.29587 3.29353 2.77316 3.43535C2.25045 3.57717 1.77372 3.85278 1.38999 4.235L1.2429 4.3825L5.6179 8.7575L5.76457 8.61042Z"
          fill="#EC6A5E"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_210">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const PlusIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_0_273)">
        <path
          d="M9.58333 4.58333H5.41667V0.416667C5.41667 0.30616 5.37277 0.200179 5.29463 0.122039C5.21649 0.0438987 5.11051 0 5 0C4.88949 0 4.78351 0.0438987 4.70537 0.122039C4.62723 0.200179 4.58333 0.30616 4.58333 0.416667V4.58333H0.416667C0.30616 4.58333 0.200179 4.62723 0.122039 4.70537C0.0438987 4.78351 0 4.88949 0 5C0 5.11051 0.0438987 5.21649 0.122039 5.29463C0.200179 5.37277 0.30616 5.41667 0.416667 5.41667H4.58333V9.58333C4.58333 9.69384 4.62723 9.79982 4.70537 9.87796C4.78351 9.9561 4.88949 10 5 10C5.11051 10 5.21649 9.9561 5.29463 9.87796C5.37277 9.79982 5.41667 9.69384 5.41667 9.58333V5.41667H9.58333C9.69384 5.41667 9.79982 5.37277 9.87796 5.29463C9.9561 5.21649 10 5.11051 10 5C10 4.88949 9.9561 4.78351 9.87796 4.70537C9.79982 4.62723 9.69384 4.58333 9.58333 4.58333Z"
          fill="#5D637E"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_273">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
