import {
  Fav2Icon,
  FavIcon,
  Paid2Icon,
  PaidIcon,
  SupchatIcon,
  Youtube2Icon,
  YoutubeIcon,
} from "./Icon";
import userAvatarImg from "../../assets/img/yashikadp.png";

export const chatTabdata = [
  {
    name: "All",
  },
  {
    icon: <SupchatIcon />,
    icon2: <SupchatIcon />,
    name: "SupChat",
  },
  {
    icon: <YoutubeIcon />,
    icon2: <Youtube2Icon />,
    name: "YouTube",
  },
  {
    icon: <FavIcon />,
    icon2: <Fav2Icon />,
    name: "Favourites",
  },
  {
    icon: <PaidIcon />,
    icon2: <Paid2Icon />,
    name: "Paid chats",
  },
];

export const chatData = [
  {
    id: 0,
    img: userAvatarImg,
    name: "Deepak Singh",
    price: "",
    tag: "",
    seen: false,
    chatMessage:
      "SuperChats is a premium library with unique Whatsapp functions with socket.",
  },
  {
    id: 1,
    img: userAvatarImg,
    name: "Ravi Raj Singh",
    price: "₹10,000",
    tag: "SupChat",
    seen: false,
    chatMessage:
      "SuperChats is a premium library with unique that functions with socket.",
  },
  {
    id: 2,
    img: userAvatarImg,
    name: "Ravi Raj Singh",
    price: "₹10,000",
    tag: "SupChat",
    seen: false,
    chatMessage:
      "SuperChats is a premium library with unique that these functions with socket.",
  },
  {
    id: 3,
    img: userAvatarImg,
    name: "Ravin",
    price: "₹10,000",
    tag: "YouTube",
    seen: true,
    chatMessage:
      "SuperChats is a premium library with unique functions with socket.",
  },
  {
    id: 4,
    img: userAvatarImg,
    name: "Kapil",
    price: "₹10,000",
    tag: "",
    seen: false,
    chatMessage: "SuperChats is a premium library with socket.",
  },
  {
    id: 5,
    img: userAvatarImg,
    name: "Kapil",
    price: "₹10,000",
    tag: "",
    seen: false,
    chatMessage: "SuperChats is a library with socket.",
  },
  {
    id: 6,
    img: userAvatarImg,
    name: "Deepak Singh",
    price: "",
    tag: "",
    seen: false,
    chatMessage:
      "SuperChats is a premium library with unique Whatsapp functions with socket.",
  },
  {
    id: 7,
    img: userAvatarImg,
    name: "Ravi Raj Singh",
    price: "₹10,000",
    tag: "SupChat",
    seen: false,
    chatMessage:
      "SuperChats is a premium library with unique that functions with socket. SuperChats is a premium library with unique that functions with socket.",
  },
  {
    id: 8,
    img: userAvatarImg,
    name: "Ravi Raj Singh",
    price: "₹10,000",
    tag: "SupChat",
    seen: true,
    chatMessage:
      "SuperChats is a premium library with unique that these functions with socket.",
  },
  {
    id: 9,
    img: userAvatarImg,
    name: "Ravin",
    price: "₹10,000",
    tag: "YouTube",
    seen: true,
    chatMessage:
      "SuperChats is a premium library with unique functions with socket.",
  },
  {
    id: 10,
    img: userAvatarImg,
    name: "Kapil",
    price: "₹10,000",
    tag: "",
    seen: false,
    chatMessage: "SuperChats is a premium library with socket.",
  },
  {
    id: 11,
    img: userAvatarImg,
    name: "Kapil",
    price: "₹10,000",
    tag: "",
    seen: true,
    chatMessage: "SuperChats is a library with socket.",
  },
];
