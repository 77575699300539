import React, { useEffect, useRef, useState } from "react";
import { chatTabdata, chatData } from "./common/Helper";
import Chatdatamodule from "./Chatdatamodule";
import LiveTimer from "./LiveTimer";
import { PinIcon, SearchIcon, SettingIcon } from "./common/Icon";
import userAvatarImg from "../assets/img/yashikadp.png";
import blackOverlay from "../assets/img/blackOverlay.png";

const Chatlayout = () => {
  const [youTubeChatData, setYouTubeChatData] = useState(chatData);
  const [chatTab, setChatTab] = useState("All");
  const [pinData, setPinData] = useState([]);
  const [favData, setFavData] = useState([]);
  const buttonRefs = useRef([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [messageInput, setMessageInput] = useState("");

  //  To center the selected tab
  useEffect(() => {
    if (buttonRefs.current[chatTab]) {
      buttonRefs.current[chatTab].scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  }, [chatTab]);

  // Filtered array to pass
  const filteredData = () => {
    let filteredData;
    switch (chatTab) {
      case "All":
        filteredData = youTubeChatData;
        break;
      case "SupChat":
        filteredData = youTubeChatData.filter((item) => item.tag == chatTab);
        break;
      case "YouTube":
        filteredData = youTubeChatData.filter((item) => item.tag == chatTab);
        break;
      case "Paid chats":
        filteredData = youTubeChatData.filter((item) => Boolean(item.price));
        break;
      default:
        filteredData = favData;
    }

    if (searchQuery) {
      filteredData = filteredData.filter(
        (item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.chatMessage.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.price.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    return filteredData;
  };

  const handleMessageSend = (e) => {
    e.preventDefault();

    if (messageInput.length > 0) {
      console.log(messageInput);
    }
  };

  return (
    <div
      className="sm:max-w-[638px] overflow-hidden w-full mx-auto 
    bg-[#17181D] sm:rounded-[10px]"
    >
      <div className="max-sm:hidden w-full h-[42px] bg-[#242424]"></div>
      <div className="sm:p-4">
        {/* Profile */}
        <div className="max-sm:p-[10px] flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <div className="w-fit h-fit rounded-full relative">
              <img src={userAvatarImg} alt="avatar" />
              <div className="bg-[#61C554] absolute bottom-0 right-0 w-[15px] h-[15px] rounded-full border-[2px] border-[#17181D]"></div>
            </div>
            <div className="flex flex-col gap-[6px]">
              <h2 className="text-white text-[16px] font-semibold tracking-[-0.35px]">
                Yashika Arora
              </h2>
              <div className="flex gap-1 items-center">
                <div className="text-white gap-[3px] flex items-center font-normal tracking-[-0.35px] text-[12px]">
                  <span className="p-[3px] bg-[#FF4141] rounded-full"></span>{" "}
                  Live on <span className="font-bold">Youtube </span>
                </div>
                <LiveTimer initialTime={4980} />
              </div>
            </div>
          </div>
          <span className="sm:mr-4 cursor-pointer hover:rotate-90 duration-300">
            <SettingIcon />
          </span>
        </div>

        {/* Tabs and input area */}
        <div className=" px-[10px] pt-2 pb-2 sm:p-2 sm:rounded-lg bg-[#1A1B20] sm:mt-4">
          {/* Navtabs */}
          <div
            className="navtab-ctr w-full sm:p-2 sm:bg-[#18191D] overflow-auto 
          sm:rounded-[35.5px]"
          >
            <div className="flex items-center gap-1 w-fit mx-auto max-sm:pb-1">
              {chatTabdata.map((item, index) => (
                <button
                  onClick={() => setChatTab(item.name)}
                  ref={(el) => (buttonRefs.current[item.name] = el)}
                  key={item.name}
                  type="submit"
                  className={`py-2 sm:py-[13px] px-3 sm:px-[17px] group duration-300 gap-1 flex items-center hover:bg-[#242636] border border-[#242636] rounded-[35px] ${
                    chatTab === chatTabdata[index].name ? "bg-[#242636]" : ""
                  }`}
                >
                  <span>{chatTab === index ? item.icon2 : item.icon}</span>
                  <p
                    className={`min-w-[56px] whitespace-nowrap group-hover:text-white text-center text-[14px] font-medium tracking-[-0.35px] w-full ${
                      chatTab === chatTabdata[index].name
                        ? " text-white"
                        : "text-[#555978]"
                    }`}
                  >
                    {item.name}
                  </p>
                </button>
              ))}
            </div>
          </div>

          {/* Search input */}
          <div className="w-full mt-2 sm:mt-4 flex gap-1 items-center rounded-lg border px-4 py-2 sm:py-[14px] border-[#2F3241] bg-[#1D1E25]">
            <SearchIcon />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search by name, keyword or superchat amount"
              className="w-full placeholder:text-[#3A3B48] placeholder:font-medium text-white outline-none bg-transparent"
            />
          </div>

          {/* Chat pin */}
          {pinData.map((items, index) => (
            <div
              key={index}
              className="w-full mt-2 sm:mt-[5px] bg-[linear-gradient(90deg,#1A1B20_0%,#33384E_50%,#1A1B20_100%)] px-4 py-[10px] flex items-center gap-3"
            >
              <button
                onClick={() => setPinData([])}
                className="flex gap-1 items-center"
              >
                <PinIcon />
                <p className="text-[#EC6A5E] text-[12px] font-normal leading-[17px]">
                  Pinned
                </p>
              </button>
              <p className="text-white text-[12px] font-normal leading-[17px]">
                <span className="font-bold">@{items.name}</span>
                <p className="line-clamp-1">{items.chatMessage}</p>
              </p>
              <h2 className="text-white text-[15px] font-bold tracking-[-0.35px]">
                {items.price}
              </h2>
            </div>
          ))}

          {/* Chat show */}
          <div className="relative overflow-hidden">
            <div
              className={`overflow-auto sm:pb-2 lg:pb-4 ${
                pinData.length !== 0
                  ? "h-[calc(100vh-291px)] sm:h-[calc(100vh-450px)] lg:h-[calc(100vh-490px)]"
                  : "h-[calc(100vh-229px)] sm:h-[calc(100vh-390px)] lg:h-[calc(100vh-430px)]"
              }`}
            >
              <div className="w-full flex flex-col mt-2 gap-[2px]">
                <Chatdatamodule
                  pinData={pinData}
                  setPinData={setPinData}
                  allData={filteredData()}
                  favData={favData}
                  setFavData={setFavData}
                  chatTab={chatTab}
                />
              </div>
            </div>
            {/* Overlay shadow */}
            <div className="max-sm:hidden w-full absolute -bottom-4 left-0 h-12 lg:h-[92px]">
              <img
                src={blackOverlay}
                alt="black overlay shadow"
                className="w-full h-full object-cover"
              />
            </div>
          </div>

          {/* Chat input */}
          <form
            onSubmit={handleMessageSend}
            className="w-full sm:w-[98%] max-sm:mt-2 duration-300 group flex gap-2 items-center sm:ml-auto p-[4px_4px_4px_21px] rounded-[26px] bg-[#2A2D3B]"
          >
            <input
              type="text"
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              placeholder="+ Add a Reply"
              className="outline-none duration-300 bg-transparent w-full placeholder:text-[#5E637E] text-sm text-white peer/input"
            />
            <button
              disabled={!messageInput.length}
              type="submit"
              className="flex-shrink-0 w-[79px] h-[35px] text-white enabled:bg-[#5156D4] enabled:hover:bg-[#474BAC] duration-300 text-[12px] font-normal leading-[17px] rounded-[26px] disabled:bg-[#242636]"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Chatlayout;
